import styled from '@emotion/styled';

import BaseLink from '@/components/common/BaseLink/BaseLink';
import { breakpoints, screen } from '@/components/common/breakpoints';
import { Icon } from '@/components/common/Icon';

import { aboutLinks, candidateLinks } from './links';

import type { LinkProps } from './links';
import type { ComponentProps } from 'react';

export interface NavProps {
  locale: string;
}

export const FooterNav = ({ locale }: NavProps) => (
  <Container>
    <NavLink href="/employers">Employers</NavLink>
    <StyledNavGroup header="Learners" links={candidateLinks(locale)} />
    <NavLink href="/community">Community</NavLink>
    <StyledNavGroup header="About" links={aboutLinks(locale)} />
    <NavLink href="/careers">Join the team</NavLink>
  </Container>
);

const StyledIcon = styled(Icon)`
  margin: var(--spacing-xxx-small) 0;
`;

const Container = styled.nav`
  grid-area: links;

  display: flex;
  gap: var(--spacing-medium);
  flex-direction: column;

  > a {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 18px;
  }

  ${screen.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface NavGroupsProps {
  className?: string;
  header: string;
  links: LinkProps[];
}

const NavGroup = ({ className, header, links }: NavGroupsProps) => (
  <div className={className}>
    <NavGroupHeader>{header}</NavGroupHeader>
    <ul>
      {links.map((link) => (
        <li key={link.href}>
          <BaseLink showVisited={false} href={link.href}>
            <>
              {link.label}
              <StyledIcon icon="ChevronRight" aria-hidden="true" />
            </>
          </BaseLink>
        </li>
      ))}
    </ul>
  </div>
);

const NavGroupHeader = styled.div`
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-loose);
`;

const StyledNavGroup = styled(NavGroup)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);

    li {
      ${screen.md} {
        white-space: pre-wrap;
      }
    }

    a {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-weight: inherit;
      font-size: inherit;

      ${screen.md} {
        padding: 0;

        svg {
          display: none;
        }
      }
    }

    ${screen.md} {
      justify-content: flex-start;
    }
  }
`;

interface NavLinkProps extends ComponentProps<typeof BaseLink> {
  target?: '_blank';
  rel?: string;
}

const NavLink = ({ children, href, ...rest }: NavLinkProps) => (
  <StyledBaseLink showVisited={false} href={href} {...rest}>
    {children}
    <StyledIcon icon="ChevronRight" aria-hidden="true" />
  </StyledBaseLink>
);

const StyledBaseLink = styled(BaseLink)`
  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }

  ${screen.md} {
    ${StyledIcon} {
      display: none;
    }
  }
`;
